import axios from 'axios';
import React from 'react';
import { useState } from 'react';
import Swal from 'sweetalert2';
import { API } from '../data/BackEndData';

const CompanyCard = ({ data, index }) => {
    const [isEdit, setIsEdit] = useState(false)
    const [company, setCompany] = useState(data.name)

    // token
    const token = JSON.parse(localStorage.getItem("token"));


    // handle Company Update
    const handleUpdate = async () => {
        try {
            await axios.patch(API + `/v1/company-update/${data._id}`, { name: company }, {
                headers: { Authorization: `bearer ${token}` },
            });
            setIsEdit(false);
            Swal.fire({
                icon: 'success',
                title: 'Company Update Successfully',
                showConfirmButton: false,
                timer: 2000
            })
            // navigate("/admin/orders")
        } catch (e) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong!'
            })
        }
    }


    return (
        <tr key={data?._id}>
            <td className={"my_row"}>
                <b>{index + 1}</b>
            </td>
            <td className={"my_row"}>
                {!isEdit ? <b>{data?.name}</b> :
                    <input type="text"
                        className="form-control"
                        value={company}
                        onChange={e => setCompany(e.target.value)}
                    />
                }

            </td>
            <td className={"my_row"}>
                {!isEdit ? <button
                    className={"btn btn-warning"}
                    onClick={() => {
                        setIsEdit(true)
                    }}
                >Edit Company</button> :
                    <button
                        className={"btn btn-warning"}
                        onClick={
                            handleUpdate
                        }
                    >Update</button>}
            </td>
        </tr>
    );
};

export default CompanyCard;