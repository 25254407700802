import axios from 'axios';
import React, { useEffect, useState } from 'react';

import { Link } from 'react-router-dom';
import { API } from './../data/BackEndData';
import { toast } from 'react-toastify';
import InfiniteScroll from 'react-infinite-scroll-component';
import { FaSearch, FaBriefcaseMedical } from 'react-icons/fa';
import StockOutFilter from './StockOutFilter';

const StockOut = () => {
  const [showFilter, setShowFilter] = useState(false);
  const [loading, setLoading] = useState(false);
  const [stockProducts, setStockProducts] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(2);
  const [sString, setSString] = useState('');

  // token
  const token = JSON.parse(localStorage.getItem('token'));

  // Get All Products from Api
  const getStockOutProduct = async () => {
    setLoading(true);
    try {
      const res = await axios.get(API + '/v1/allStock-out-product', {
        headers: { Authorization: `bearer ${token}` },
      });
      const otherStock = [...res?.data];
      setStockProducts(otherStock);
      setLoading(false);
    } catch (e) {
      toast.error(e.response.data.error || 'Reload This Page!');
      setLoading(false);
    }
  };

  useEffect(() => {
    getStockOutProduct();
  }, []);

  // get infinity
  const getInfinitProduct = async () => {
    setLoading(false);
    try {
      const res = await axios.get(
        API + `/v1/allStock-out-product?page=${page}`,
        {
          headers: { Authorization: `bearer ${token}` },
        }
      );

      setStockProducts([...stockProducts, ...res?.data]);
      if (res?.data.length == 0 || res?.data.length < 20) {
        setHasMore(false);
      }
    } catch (e) {
      toast.error(e.response.data.error || 'Reload This Page!');
    }
  };

  // fetch data
  const fetchData = async () => {
    await getInfinitProduct();
    if (hasMore) {
      setPage(page + 1);
    }
  };

  // handle Search function
  const handleSearch = async (e) => {
    const query = e.target.value.trimStart();

    // if (query) {
    //   const mProduct = copyProducts.filter((el) =>
    //     el.title.toLowerCase().includes(query.toLowerCase())
    //   );
    //   setProducts(mProduct);
    // } else {
    //   setProducts(copyProducts);
    // }

    let cancelToken;
    if (typeof cancelToken != typeof undefined) {
      cancelToken.cancel('Cancel previous request');
    }

    cancelToken = axios.CancelToken.source();
    setSString(query);
    setPage(1);
    let limit = 100;

    try {
      const res = await axios.get(
        API + `/v1/allStock-out-product?page=1&s=${query}&limit=${limit}`,
        {
          headers: { Authorization: `bearer ${token}` },
          cancelToken: cancelToken.token,
        }
      );
      setStockProducts(res?.data);

      if (res?.data.length === 0 || res?.data.length < limit) {
        setHasMore(false);
      } else {
        setHasMore(true);
      }
    } catch (e) {}
  };

  return (
    <section className="mt-5">
      <article className="container">
        <div className="d-flex justify-content-between">
          <h4 className="position-relative">
            <span className="text-danger fw-bold">Stock Out Products</span>
          </h4>
        </div>
        <div className="input-group" style={{ width: '75%' }}>
          <input
            style={{ width: '75%' }}
            type="text"
            className="form-control"
            placeholder="Search Here"
            onChange={(e) => handleSearch(e)}
          />
          <span className="input-group-text" id="basic-addon2">
            <FaSearch />
          </span>
          <div>
            <FaBriefcaseMedical
              size={35}
              style={{
                marginLeft: '10px',
                fontSize: '25px',
                cursor: 'pointer',
              }}
              onClick={() => setShowFilter(!showFilter)}
            />
          </div>
        </div>

        <hr />

        {loading ? (
          <div className="text-center mt-4">
            <div className="spinner-border text-primary" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        ) : (
          <div>
            {stockProducts.length < 1 ? (
              <div
                className="alert alert-danger text-center text-secondary"
                role="alert"
              >
                <h5 className="mt-5 font-bold">
                  Your Searched Product not found..! <br />
                  Please Enter Correct Spelling!
                </h5>
              </div>
            ) : (
              <InfiniteScroll
                dataLength={stockProducts.length}
                next={fetchData}
                hasMore={hasMore}
              >
                {' '}
                <table className="table table-hover">
                  <thead>
                    <tr>
                      <th>Products Name</th>
                      <th>Quantity</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {stockProducts.map(
                      (data, index) =>
                        data.stock < 1 && (
                          <tr key={index} className="bg-warning">
                            <td className={'my-row'}>{data.title}</td>
                            <td className={'my-row'}>{data.stock}</td>
                            <td className={'my-row'}>
                              <Link
                                to={`/admin/product/${data?._id}`}
                                className="btn btn-sm btn-danger"
                              >
                                Update
                              </Link>
                            </td>
                          </tr>
                        )
                    )}
                  </tbody>
                </table>
              </InfiniteScroll>
            )}
          </div>
        )}
        <StockOutFilter showFilter={showFilter} setShowFilter={setShowFilter} />
      </article>
    </section>
  );
};

export default StockOut;
