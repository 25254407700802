import style from '../styles/home/home_filter.module.css';
import { MdClose } from 'react-icons/md';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { API } from '../data/BackEndData';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

function ProductFilter({ showFilter, setShowFilter }) {
  const [comanys, setComapnys] = useState([]);
  const [cloneCompany, setCloneComapnys] = useState([]);
  const [loading, setLoading] = useState(false);
  const token = JSON.parse(localStorage.getItem('token'));

  let navigate = useNavigate();

  //   get all company
  const getCompany = async () => {
    setLoading(true);
    try {
      const res = await axios.get(API + '/v1/company', {
        headers: { Authorization: `bearer ${token}` },
      });
      setComapnys(res?.data?.company);
      setCloneComapnys(res?.data?.company);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Something went Wrong!',
      });
    }
  };

  useEffect(() => {
    getCompany();
  }, []);

  //   handle Company Search
  const handelCompanySearch = (e) => {
    const query = e.target.value.trimStart();
    if (query) {
      const mCompany = comanys.filter((el) =>
        el.name.toLowerCase().includes(query.toLowerCase())
      );
      setComapnys(mCompany);
    } else {
      setComapnys(cloneCompany);
    }
  };

  //   Handle Checked
  const handelChecked = async (e) => {
    const val = e.target.value;
    setShowFilter(false);
    navigate(`/filter-product/${val}`);
  };

  //   Handle Clear
  const handelClear = () => {
    navigate('/product');
  };

  return (
    <div className={`${showFilter ? style.show : ''} ${style.section}`}>
      <div className="container">
        <div className={style.wrapper}>
          <div className={style.header}>
            <h5 className="fw-6 mb-0">Filter By</h5>
            <div
              className={style.close}
              onClick={() => setShowFilter(!showFilter)}
            >
              <MdClose />
            </div>
          </div>
          <div className={style.searcharea}>
            <p className={style.company}>
              <b>Company</b>
            </p>
            <div className={style.input_box}>
              <input
                type="text"
                placeholder="Search Company"
                onChange={(e) => handelCompanySearch(e)}
              />
            </div>
          </div>
          <div>
            <div className={`form-check ${style.my_check}`}>
              <label className="form-check-label" htmlFor="re">
                <b>Clear Select </b>
              </label>
              <input
                className="form-check-input"
                type="checkbox"
                value={''}
                id={'re'}
                onChange={(e) => handelClear(e)}
              />
            </div>
            {comanys?.map((data, index) => (
              <div key={data?._id} className={`form-check ${style.my_check}`}>
                <label className="form-check-label" htmlFor={data}>
                  <b>{data?.name}</b>
                </label>
                <input
                  className="form-check-input"
                  type="checkbox"
                  value={data?.name}
                  id={data}
                  onChange={(e) => handelChecked(e)}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProductFilter;
