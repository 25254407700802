import OrderDetailsHeader from "./../components/OrderDeatils/OrderDetailsHeader";
import { useState, useEffect } from "react";
import axios from "axios";
import { API } from "./../data/BackEndData";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import React, { useRef } from "react";
import ReactToPrint from "react-to-print";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

function AdminOrderDetails() {
  const [loading, setLoading] = useState(false);
  const [orders, setOrders] = useState({});
  // console.log(orders);
  let { id } = useParams();

  let navigate = useNavigate();

  const token = JSON.parse(localStorage.getItem("token"));

  const fetchOrder = async () => {
    setLoading(true);
    try {
      const res = await axios.get(API + "/v1/order/" + id, {
        headers: { Authorization: `bearer ${token}` },
      });
      setOrders(res?.data);
      setLoading(false);
    } catch (e) {
      toast.error(e.response.data.error || "Relaod this web page.");
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchOrder();
  }, []);
  const componentRef = useRef();

  // Handle delete
  // const handelDelete = async () => {
  //   // get modal 
  //   Swal.fire({
  //     title: 'Are you sure?',
  //     text: "You won't be able to revert this!",
  //     icon: 'warning',
  //     showCancelButton: true,
  //     confirmButtonColor: '#3085d6',
  //     cancelButtonColor: '#d33',
  //     confirmButtonText: 'Yes, delete it!'

  //   }).then(async(result) => {
  //     if (result.isConfirmed) {
  //       try {
  //         const res = await axios.delete(API + `/v1/order/${orders._id}`, {
  //           headers: { Authorization: `bearer ${token}` },
  //         });
  //         // <Navigate to="/admin/orders" replace={true} />;
  //         navigate("/admin/orders");
  //         Swal.fire(
  //           'Deleted!',
  //           'Your file has been deleted.',
  //           'success'
  //         )
  //       } catch (e) {
  //         Swal.fire({
  //           icon: 'error',
  //           title: 'Oops...',
  //           text: 'Something went Wrong!'
  //         })
  //       }
  //     }
  //   })
  // };

  const handelComplete = async (id) => {
    try {
      const res = await axios.patch(API + `/v1/complete-order/${id}`, {}, {
        headers: { Authorization: `bearer ${token}` },
      });
      Swal.fire({
        icon: 'success',
        title: 'Order Complete yet Successfully',
        showConfirmButton: false,
        timer: 2000
      })
      navigate("/admin/orders")
    } catch (e) {
      console.log(e, "something")
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Something went wrong!'
      })
    }
  };

  return (
    <>
      <div className="container">
        <ReactToPrint
          trigger={() => (
            <button className="btn btn-outline-primary btn-sm mt-2 me-3">
              Print this out!
            </button>
          )}
          content={() => componentRef.current}
        />
        {/* <button className="btn btn-danger btn-sm mt-2" onClick={handelDelete}>
          Delete
        </button> */}
        {orders.status || <button
          className="btn btn-primary btn-sm mt-2 ms-3"
          onClick={() => handelComplete(orders._id)}
        >
          Complete
        </button>}
      </div>
      <div ref={componentRef}>
        <OrderDetailsHeader orders={orders} />

        {orders?.items?.length > 0 ? (
          <div className="container">
            <table className="table table-bordered">
              <thead>
                <tr className="text-center">
                  <th>SL.</th>
                  <th>ITEM</th>
                  <th>QTY</th>
                  <th>RATE</th>
                  <th>AMOUNT</th>
                  <th>DISC</th>
                  <th>NETPAY</th>
                </tr>
              </thead>
              <tbody>
                {orders?.items?.map((data, index) => (
                  <tr key={data?._id}>
                    <td style={{ padding: "0px 8px", fontSize: "13px" }}>
                      {index + 1}
                    </td>
                    <td style={{ padding: "0px 10px", fontSize: "13px" }}>
                      {data?.title}
                    </td>
                    <td style={{ padding: "0px 6px", fontSize: "13px" }}>
                      {data?.quantity}
                    </td>
                    <td style={{ padding: "0px 8px", fontSize: "13px" }}>
                      {data?.mainPrice}
                    </td>
                    <td style={{ padding: "0px 8px", fontSize: "13px" }}>
                      {(data?.quantity * data?.mainPrice).toFixed(2)}
                    </td>
                    <td style={{ padding: "0px 8px", fontSize: "13px" }}>
                      {(
                        (data.quantity * data.mainPrice * data.discount) /
                        100
                      ).toFixed(2)}
                    </td>
                    <td style={{ padding: "0px 8px", fontSize: "13px" }}>
                      {(
                        data.quantity * data.mainPrice -
                        (data.quantity * data.mainPrice * data.discount) / 100
                      ).toFixed(2)}
                    </td>
                  </tr>
                ))}
              </tbody>
              <tbody>
                <tr>
                  <td style={{ padding: "0px 8px", fontSize: "13px" }}></td>
                  <td style={{ padding: "0px 8px", fontSize: "13px" }}></td>
                  <td style={{ padding: "0px 8px", fontSize: "13px" }}></td>
                  <td style={{ padding: "0px 8px", fontSize: "13px" }}></td>
                  <td style={{ padding: "0px 8px", fontSize: "13px" }}></td>

                  <td style={{ padding: "0px 8px", fontSize: "13px" }}>
                    Total=
                  </td>
                  <td style={{ padding: "0px 8px", fontSize: "13px" }}>
                    {(orders?.cartTotal).toFixed(2)}
                  </td>
                </tr>
              </tbody>
            </table>
            {/* <footer style={{ marginTop: "55%"}}>
              <div style={{position: "relative"}} className="container mt-5 d-flex align-items-center justify-content-between">
                <p>Customer Signature</p>
                <p>Helpline: 01907-365800</p>
                <p>Authorised Signature</p>
              </div>
            </footer> */}
          </div>
        ) : (
          <div className="container mt-4">
            <div className="alert alert-primary" role="alert">
              No order Found!
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default AdminOrderDetails;
