import React from 'react';

import UpdateCart from './UpdateCart';
// import axios from 'axios';
// import Swal from 'sweetalert2';

function SingleCart({ getCartTotal, getCartItem, cartItems = [] }) {
  if (cartItems?.length === 0) return <p>Your cart is empty</p>;
  return (
    <>
      {cartItems?.map((data) => (
        <UpdateCart
          data={data}
          key={data._id}
          getCartItem={getCartItem}
          getCartTotal={getCartTotal}
        />
      ))}
    </>
  );
}

export default SingleCart;
