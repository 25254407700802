import style from "../../styles/home/home_filter.module.css";
import { MdClose } from "react-icons/md";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import { API } from "./../../data/BackEndData";
import { useNavigate } from 'react-router-dom';

function HomeFilter({
  showFilter,
  setShowFilter,
}) {
  const [comanys, setComapnys] = useState([]);
  const [cloneCompany, setCloneComapnys] = useState([]);
  const [loading, setLoading] = useState(false);
  const token = JSON.parse(localStorage.getItem("token"));

  let navigate = useNavigate();

  const getCompany = async () => {
    setLoading(true);
    try {
      const res = await axios.get(API + "/v1/company", {
        headers: { Authorization: `bearer ${token}` },
      });
      setComapnys(res?.data?.company);
      setCloneComapnys(res?.data?.company);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      toast.error(e.response.data.error || "Reload and Try Again!");
    }
  };

  useEffect(() => {
    getCompany();
  }, []);


  const handelCompanySearch = (e) => {
    const query = e.target.value.trimStart();
    if (query) {
      const mCompany = comanys.filter((el) =>
        el.name.toLowerCase().includes(query.toLowerCase())
      );
      setComapnys(mCompany);
    } else {
      setComapnys(cloneCompany);
    }
  };

  const handelChecked = async (e) => {
    const val = e.target.value;
    setShowFilter(false);
    navigate(`/pro-com/${val}`);
  };

  const handelClear = () => {
    navigate('/');
  }

  return (
    <div className={`${showFilter ? style.show : ""} ${style.section}`}>
      <div className="container">
        <div className={style.wrapper}>
          <div className={style.header}>
            <h5 className="fw-6 mb-0">Filter By</h5>
            <div
              className={style.close}
              onClick={() => setShowFilter(!showFilter)}
            >
              <MdClose />
            </div>
          </div>
          <div className={style.searcharea}>
            <p className={style.company}>
              <b>Company</b>
            </p>
            <div className={style.input_box}>
              <input
                type="text"
                placeholder="Search Company"
                onChange={(e) => handelCompanySearch(e)}
              />
            </div>
          </div>
          {/* add style */}
          <div className="w-100">
            <div className={`form-check w-100 gap-1 ${style.my_check}`}>
              <div style={{ flexBasis: "90%" }} className="overflow-hidden">
                <p className="m-0 fw-bold">
                  Clear Select
                </p>
              </div>
              <div style={{ flexBasis: "10%" }}>
                <div className="d-flex justify-content-center">
                  <input
                    // className="form-check-input"
                    type="checkbox"
                    value={""}
                    id={"re"}
                    onChange={(e) => handelClear(e)}
                  />
                </div>
              </div>
            </div>
            {comanys?.map((data, index) => (
              <div key={data?._id} className={`form-check w-100 gap-1 ${style.my_check}`}>
                <div style={{ flexBasis: "90%" }} className="overflow-hidden">
                  <p className="m-0 fw-bold">
                    {data?.name}
                  </p>
                </div>
                <div style={{ flexBasis: "10%" }}>
                  <div className="d-flex justify-content-center">
                    <input
                      // className="form-check-input"
                      type="checkbox"
                      value={data?.name}
                      id={data}
                      onChange={(e) => handelChecked(e)}
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomeFilter;
