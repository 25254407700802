
import Cart from '../components/Home/Cart/Cart';

function CartPage() {
  return (
    <div>
       <Cart />
    </div>
  )
}

export default CartPage;