import moment from 'moment';
import Logo from '../../image/Logo.png';
import style from '../../styles/orderdetails/OrderHeader.module.css';

function OrderDetailsHeader({ orders }) {
  const formattedDate = moment(orders?.date).utc().format('DD/MM/YY');

  // console.log(formattedDate);
  return (
    <section className="border border-top-0 border-left-0 border-right-0">
      <article className="container">
        <div className={style.header_Container}>
          <img className={style.logo} src={Logo} alt="Logo" />
          <h4 className={style.headTitle}>Mohammadi Drug House</h4>
        </div>

        <div className={style.address}>
          <p className="text-center text-secondary">
            Arifabad Housing Society,Mirpur,Dhaka-1216 <br />
            Mobile: +88 01875-212872
            <br />
            <span className={style.invoice}> Invoice</span>
          </p>
        </div>

        <table className="table table-bordered">
          <tbody>
            <tr>
              <td>
                <pre className="d-flex align-items-center justify-content-between">
                  Order ID : {orders?._id} <br />
                  Customer Name : {orders?.user?.pharmacyName} <br />
                  Customer Number : {orders?.user?.number} <br />
                  Customer Address: {orders?.user?.address} <br />
                </pre>
              </td>
              <td>
                <tr>
                  <pre>
                    Order Date : {formattedDate} <br />
                    Invoice Type : CASH
                  </pre>
                </tr>
              </td>
            </tr>
          </tbody>
        </table>
      </article>
    </section>
  );
}

export default OrderDetailsHeader;
