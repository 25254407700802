export const notificatoinData = [
  {
    id: 0,
    title: "Good News",
    date: "76786",
    des: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Ducimus, reprehenderit natus officiis, ipsum error quidem soluta veritatis nostrum velit dolores facere similique! Expedita, cupiditate ex consequatur minus doloribus commodi maxime.",
  },
  {
    id: 1,
    title: "Good News",
    date: "76786",
    des: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Ducimus, reprehenderit natus officiis, ipsum error quidem soluta veritatis nostrum velit dolores facere similique! Expedita, cupiditate ex consequatur minus doloribus commodi maxime.",
  },
  {
    id: 2,
    title: "Good News",
    date: "76786",
    des: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Ducimus, reprehenderit natus officiis, ipsum error quidem soluta veritatis nostrum velit dolores facere similique! Expedita, cupiditate ex consequatur minus doloribus commodi maxime.",
  },
  {
    id: 3,
    title: "Good News",
    date: "76786",
    des: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Ducimus, reprehenderit natus officiis, ipsum error quidem soluta veritatis nostrum velit dolores facere similique! Expedita, cupiditate ex consequatur minus doloribus commodi maxime.",
  },
];
