import HomePageHeader from './../components/Home/HomePageHeader';
import HomePageCards from './../components/Home/HomePageCards';
import HomeFilter from './../components/Home/HomeFilter';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { API } from './../data/BackEndData';
import { toast } from 'react-toastify';
import InfiniteScroll from 'react-infinite-scroll-component';

function HomePage() {
  const [showFilter, setShowFilter] = useState(false);
  const [products, setProducts] = useState([]);
  const [copyProducts, setCopyProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [banner, setBanner] = useState('');
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [showBanner, steShowBanner] = useState(true);
  const [s, setS] = useState('');
  const [query, setQuery] = useState(``);
  const [length, setLength] = useState(0);

  const token = JSON.parse(localStorage.getItem('token'));

  const getProduct = async () => {
    setPage(1);
    setLoading(true);
    try {
      const res = await axios.get(API + `/v1/get-product?page=${page}&s=${s}`, {
        headers: { Authorization: `bearer ${token}` },
      });
      setProducts([...products, ...res?.data]);
      //experiment start
      if (res.data.length === 20) {
        const p = res.data.filter((p) => p.stock == 0);
        // console.log('plength', p.length);
        setLength(length + p.length);
      }
      //experiment end
      setCopyProducts(res?.data);
      setLoading(false);
      if (res?.data.length === 0 || res?.data.length < 20) {
        setHasMore(false);
      } else {
        setPage(page + 1);
      }
    } catch (e) {
      toast.error(e.response.data.error || 'Reload This Page!');
      setLoading(false);
    }
  };

  const getSearchProduct = async () => {
    try {
      const res = await axios.get(API + `/v1/get-product?page=${page}&s=${s}`, {
        headers: { Authorization: `bearer ${token}` },
      });
      setProducts([res?.data]);
      setCopyProducts(res?.data);
      setLoading(false);
      if (res?.data.length === 0 || res?.data.length < 20) {
        setHasMore(false);
      }
    } catch (e) {
      toast.error(e.response.data.error || 'Reload This Page!');
      setLoading(false);
    }
  };

  // get banner
  const getBanner = async () => {
    try {
      const res = await axios.get(API + '/v1/get-banner', {
        headers: { Authorization: `bearer ${token}` },
      });

      setBanner(res?.data?.message[0]?.bannerUrl);
    } catch (e) {
      // console.log(e);
    }
  };

  useEffect(() => {
    // console.log("call from useEffect");
    getProduct();
    getBanner();
  }, []);

  // handel sort
  const handelSort = () => {
    console.log('handel short call');
  };

  const getInfinitProduct = async () => {
    setLoading(false);
    try {
      const res = await axios.get(API + `/v1/get-product?page=${page}`, {
        headers: { Authorization: `bearer ${token}` },
      });
      setProducts([...products, ...res?.data]);
      //experiment start
      if (res.data.length === 20) {
        if (length < 20) {
          const p = res.data.filter((p) => p.stock == 0);
          setLength(length + p.length);
        }
      }
      //experiment end
      setCopyProducts(res?.data);
      setLoading(false);
      if (res?.data.length === 0 || res?.data.length < 20) {
        console.log('res?.data.length', res?.data.length);
        setHasMore(false);
      }
    } catch (e) {
      toast.error(e.response.data.error || 'Reload This Page!');
      setLoading(false);
    }
  };

  // infinit call
  const fetchData = async () => {
    await getInfinitProduct();
    if (hasMore) {
      setPage(page + 1);
    }
  };

  //experiment
  useEffect(() => {
    if (length < 20) {
      getInfinitProduct();
      if (hasMore) {
        setPage(page + 1);
      }
    }
  }, [length]);
  //experiment end

  return (
    <>
      <HomePageHeader
        showFilter={showFilter}
        setShowFilter={setShowFilter}
        products={products}
        setProducts={setProducts}
        copyProducts={copyProducts}
        handelSort={handelSort}
        steShowBanner={steShowBanner}
        setS={setS}
        getSearchProduct={getSearchProduct}
        hasMore={hasMore}
        setHasMore={setHasMore}
        page={page}
        setPage={setPage}
      />
      {showBanner && (
        <div className="container">
          <div className="banner-conatiner">
            <img src={banner} alt="" />
          </div>
        </div>
      )}

      <InfiniteScroll
        dataLength={products.length}
        next={fetchData}
        hasMore={hasMore}
      >
        <HomePageCards products={products} loading={loading} />
      </InfiniteScroll>
      <HomeFilter showFilter={showFilter} setShowFilter={setShowFilter} />
    </>
  );
}

export default HomePage;
