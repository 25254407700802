import axios from 'axios';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import Swal from 'sweetalert2';
import { API } from '../data/BackEndData';
import { HiOutlineCurrencyBangladeshi } from 'react-icons/hi';
import style from '../styles/home/home_page_card.module.css';
import { toast } from 'react-toastify';
import { FaSearch } from 'react-icons/fa';

import OrderCard from './OrderCard';

const Cash = () => {
  const [cash, setCash] = useState(0);
  const [loading, setLoading] = useState(false);
  const [completeOrder, setCompleteOrder] = useState([]);
  const [pharmacyName, setPharmacyName] = useState('');
  const [filterOrder, setFilterOrder] = useState(completeOrder);

  const getDate = new Date();

  // get Token
  const token = JSON.parse(localStorage.getItem('token'));

  // get all complete orders
  const getAllCompleteOrder = async () => {
    let total = 0;
    setLoading(true);
    try {
      const res = await axios.get(API + '/v1/complete-order', {
        headers: { Authorization: `bearer ${token}` },
      });
      const complete = [...res?.data];
      setCompleteOrder(complete);
      complete.forEach((item) => {
        total = total + item.cartTotal;
      });
      setCash(total);
      setLoading(false);
    } catch (e) {
      toast.error(e.response.data.error || 'Reload This Page!');
      setLoading(false);
    }
  };

  // all complete Order Delete
  // const deleteCompleteOrder = () => {
  //   completeOrder.forEach(async (element) => {
  //     axios.delete(API + `/v1/order/${element._id}`, {
  //       headers: { Authorization: `bearer ${token}` },
  //     });
  //   })
  // }

  const handleSearch = async (e) => {
    const query = e.target.value.toLowerCase().trimStart();
    setPharmacyName(query);
  };

  useEffect(() => {
    if (pharmacyName) {
      const filterOrder = completeOrder.filter((order) =>
        order?.user?.pharmacyName.toLowerCase().includes(pharmacyName)
      );
      setFilterOrder(filterOrder);
    } else {
      setFilterOrder(completeOrder);
    }
  }, [pharmacyName, completeOrder]);

  useEffect(() => {
    getAllCompleteOrder();
  }, []);

  //   useEffect for Each
  useEffect(() => {
    let total = 0;
    completeOrder.forEach((element) => {
      const loadingCash = element.cartTotal;
      total = total + loadingCash;
    });
    setCash(total);
  }, [completeOrder]);

  //   Handle Reset
  const handleReset = () => {
    // get modal
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then(async (result) => {
      if (result.isConfirmed) {
        await axios.delete(API + `/v1/allCompleteOrder`, {
          headers: { Authorization: `bearer ${token}` },
        });

        window.location.reload();

        Swal.fire('Deleted!', 'All Order has been deleted.', 'success');
      }
    });
  };

  return (
    <section className="mt-5">
      <article className="container">
        <h5 className="card-title text-center">
          <b>Today Transaction History</b>
        </h5>

        <div className="mt-5 h-50">
          <div className={style.card}>
            <div className="row">
              <div className="col-md-6">
                <p className="fw-bold">
                  Date:{' '}
                  {`${getDate.getDate()} / ${
                    getDate.getMonth() + 1
                  } / ${getDate.getFullYear()}`}{' '}
                </p>
              </div>

              <div className="col-md-6">
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search Here"
                    onChange={(e) => handleSearch(e)}
                  />
                  <span className="input-group-text" id="basic-addon2">
                    <FaSearch />
                  </span>
                </div>
              </div>

              <div className="col-md-6">
                <p className="fw-bold">
                  Today Complete Order: {completeOrder?.length}
                </p>
              </div>
              <div className="col-md-6">
                <p className="fw-bold">
                  Total Cash: {cash.toFixed(2)}{' '}
                  <span>
                    <HiOutlineCurrencyBangladeshi className={style.tk} />
                  </span>
                </p>
              </div>
            </div>
            <div>
              <button
                onClick={handleReset}
                className="btn btn-lg btn-block w-100 btn-danger"
              >
                Delete All Complete Order
              </button>
            </div>
          </div>
        </div>
        <div>
          {filterOrder.map((data) => (
            <OrderCard
              data={data}
              // handelDelete={handelDelete}
              key={data._id}
            />
          ))}
        </div>
      </article>
    </section>
  );
};

export default Cash;
