import React, { useState, useEffect } from 'react';
import axios from 'axios';

function SingleFileUpload({ file, setImgUrl }) {
  const [percentage, setPercentage] = useState(0);

  useEffect(() => {
    // const url = 'https://api.cloudinary.com/v1_1/dvn8t2abj/image/upload'; old
    const url = 'https://api.cloudinary.com/v1_1/dsmr0jrig/image/upload'; 
    const data = new FormData();
    data.append('file', file);
    // data.append('upload_preset', 'ou01wnuu');
    data.append('upload_preset', 'd5rkumfq');

    const option = {
      onUploadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;
        const percent = Math.round((loaded * 100) / total);
        setPercentage(percent);
      },
    };

    axios.post(url, data, option).then((res) => setImgUrl(res.data.secure_url));
  }, [file]);
  return (
    <div className={percentage == 100 ? 'd-none' : 'd-block'}>
      <div className="mt-2">
        <div className="progress">
          <div
            className="progress-bar progress-bar-striped progress-bar-animated"
            role="progressbar"
            style={{ width: `${percentage}%` }}
          >
            Uploading
          </div>
        </div>
      </div>
    </div>
  );
}

export default SingleFileUpload;
