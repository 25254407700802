import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import style from "../styles/MyOrder/MyOrder.module.css";



const OrderCard = ({ data }) => {
  // const [isComplete, setIsComplete] = useState(false)
  // const localData = JSON.parse(localStorage.getItem("completeOrder")) || [];
  // console.log(data)
  // useEffect(() => {
  //   const exist = localData.find(d => d._id == data._id)
  //   if (exist) {
  //     setIsComplete(true)
  //   }
  //   else {
  //     setIsComplete(false)
  //   }
  // }, [data])



  return (
    <div className="card mb-3">
      <div className="d-flex px-3 pt-3 pb-2 justify-content-between">
        <div>
          <h6 className="card-title">Order Id: {data?._id}</h6>
          <p>{data?.user?.pharmacyName}</p>
          <p>{data?.user?.name}</p>
        </div>
        <div className="d-flex flex-column	align-items-end">
          <p className={style.price}>{data?.cartTotal.toFixed(2)}</p>

          <p
            className={
              data?.status === true
                ? style.success
                : data === false
                  ? style.going
                  : style.return
            }
          >
            {
              data?.status == true ? <p className="text-primary fw-bold">Complete</p> : <p className="text-dark fw-bold">Pending</p>
            }
          </p>
          <div className="d-flex">
            <Link to={`/admin/orders/${data?._id}`}>
              <button className="btn btn-sm  btn-danger me-2">View </button>
            </Link>
            {/* <button
              onClick={() => handelDelete(data?._id)}
              className="btn btn-sm  btn-danger"
            >
              Delete
            </button> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderCard;
