import React, { useState, useEffect } from 'react';
import axios from 'axios';
import InfiniteScroll from 'react-infinite-scroll-component';
import { API } from '../data/BackEndData';
import { Link, useParams } from 'react-router-dom';
import { FaSearch } from 'react-icons/fa';
import ProductFilter from './ProductFilter';
import { MdStorage } from 'react-icons/md';
import { toast } from 'react-toastify';

function FilterProducts() {
  const [showFilter, setShowFilter] = useState(false);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const { com } = useParams();
  const token = JSON.parse(localStorage.getItem('token'));

  // get Product
  const fetchProducts = async () => {
    setLoading(true);
    try {
      const res = await axios.get(
        API + `/v1/get-product-by-com?page=${page}&c=${com}`,
        {
          headers: { Authorization: `bearer ${token}` },
        }
      );
      setProducts([...products, ...res?.data]);
      setLoading(false);
      if (res?.data.length === 0 || res?.data.length < 20) {
        setHasMore(false);
      } else {
        setPage(page + 1);
      }
    } catch (e) {
      toast.error(e.response.data.error || 'Reload This Page!');
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProducts();
  }, []);

  // getInfinite
  const getInfiniteProduct = async () => {
    setLoading(false);
    try {
      const res = await axios.get(
        API + `/v1/get-product-by-com?page=${page}&c=${com}`,
        {
          headers: { Authorization: `bearer ${token}` },
        }
      );
      setProducts([...products, ...res?.data]);

      setLoading(false);
      if (res?.data.length === 0 || res?.data.length < 20) {
        setHasMore(false);
      }
    } catch (e) {
      toast.error(e.response.data.error || 'Reload This Page!');
      setLoading(false);
    }
  };

  // infinity call
  const fetchNextData = async () => {
    await getInfiniteProduct();
    if (hasMore) {
      setPage(page + 1);
    }
  };

  // handle search data
  const handleSearch = async (e) => {
    const query = e.target.value.trimStart();
    setSearchTerm(query);
    setPage(1);
    try {
      const res = await axios.get(
        `${API}/v1/get-product?page=1&s=${query}&limit=100`,
        {
          headers: { Authorization: `bearer ${token}` },
        }
      );
      setProducts(res.data);
      setHasMore(res.data.length >= 20); // Check if there's more data to load
    } catch (error) {
      console.log(error);
    }
  };

  const filteredProducts = products.filter((product) =>
    product.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <section className="mt-5">
      <article className="container">
        <div className="d-flex justify-content-between">
          <h4 className="position-relative">
            <span className="text-danger fs-5">Products By Company </span>
            <span className="text-primary fs-5 ">
              {' '}
              {filteredProducts.length > 0 && filteredProducts[0].companyName}
            </span>
          </h4>
        </div>
        <div className="input-group" style={{ width: '75%' }}>
          <input
            style={{ width: '75%' }}
            type="text"
            className="form-control"
            placeholder="Search Here"
            onChange={handleSearch}
          />
          <span className="input-group-text" id="basic-addon2">
            <FaSearch />
          </span>
          <div>
            <MdStorage
              size={35}
              style={{
                marginLeft: '20px',
                fontSize: '25px',
                cursor: 'pointer',
              }}
              onClick={() => setShowFilter(!showFilter)}
            />
          </div>
        </div>

        <hr />

        {loading ? (
          <div className="text-center mt-4">
            <div className="spinner-border text-primary" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        ) : (
          <div>
            {filteredProducts.length === 0 ? (
              <div
                className="alert alert-danger text-center text-secondary"
                role="alert"
              >
                <h5 className="mt-5 font-bold">
                  Your searched product not found..! <br />
                  Please enter correct spelling!
                </h5>
              </div>
            ) : (
              <InfiniteScroll
                dataLength={filteredProducts.length}
                next={fetchNextData}
                hasMore={hasMore}
              >
                <table className="table table table-striped table-hover">
                  <thead>
                    <tr>
                      <th>Products Name</th>
                      <th>Quantity</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredProducts.map((data, index) => (
                      <tr key={index}>
                        <td className={'my-row'}>{data.title}</td>
                        <td className={'my-row'}>{data.stock}</td>
                        <td className={'my-row'}>
                          <Link
                            to={`/admin/product/${data._id}`}
                            className="btn btn-sm btn-danger"
                          >
                            Update
                          </Link>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </InfiniteScroll>
            )}
          </div>
        )}
        <ProductFilter showFilter={showFilter} setShowFilter={setShowFilter} />
      </article>
    </section>
  );
}

export default FilterProducts;
