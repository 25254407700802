import { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import InfiniteScroll from "react-infinite-scroll-component";
import HomePageHeader from "../components/Home/HomePageHeader";
import HomePageCards from "../components/Home/HomePageCards";
import HomeFilter from "../components/Home/HomeFilter";
import { API } from "../data/BackEndData";
import { useParams } from "react-router-dom";

function ProductCom() {
  const [showFilter, setShowFilter] = useState(false);
  const [products, setProducts] = useState([]);
  const [copyProducts, setCopyProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [s, setS] = useState("");

  let { com } = useParams();

  const token = JSON.parse(localStorage.getItem("token"));

  // get Product
  const getProduct = async () => {
    setLoading(true);
    try {
      const res = await axios.get(
        API + `/v1/get-product-by-com?page=${page}&c=${com}`,
        {
          headers: { Authorization: `bearer ${token}` },
        }
      );
      // console.log(res?.data);
      setProducts([...products, ...res?.data]);
      setCopyProducts(res?.data);
      setLoading(false);
      if (res?.data.length === 0 || res?.data.length < 20) {

        setHasMore(false);
      } else {
        setPage(page + 1);
      }
    } catch (e) {
      toast.error(e.response.data.error || "Reload This Page!");
      setLoading(false);
    }
  };

  useEffect(() => {
    getProduct();
  }, []);

  const getInfinitProduct = async () => {
    setLoading(false);
    // console.log("Call from get infinit", "s", s);
    try {
      const res = await axios.get(
        API + `/v1/get-product-by-com?page=${page}&c=${com}`,
        {
          headers: { Authorization: `bearer ${token}` },
        }
      );
      setProducts([...products, ...res?.data]);
      setCopyProducts(res?.data);
      // console.log("call infuin api---");
      setLoading(false);
      if (res?.data.length === 0 || res?.data.length < 20) {
        // console.log("res?.data.length", res?.data.length);
        setHasMore(false);
      }
    } catch (e) {
      toast.error(e.response.data.error || "Reload This Page!");
      setLoading(false);
    }
  };

  // infinit call
  const fetchData = async () => {
    await getInfinitProduct();
    if (hasMore) {
      // console.log("Page ++");
      setPage(page + 1);
    }
  };

  return (
    <>
      <HomePageHeader
        showFilter={showFilter}
        setShowFilter={setShowFilter}
        setProducts={setProducts}
        copyProducts={copyProducts}
        setS={setS}
        hasMore={hasMore}
        setHasMore={setHasMore}
        page={page}
        setPage={setPage}
      />

      <InfiniteScroll
        dataLength={products.length}
        next={fetchData}
        hasMore={hasMore}
      >
        {products.length > 0 ? (
          <HomePageCards products={products} loading={loading} />
        ) : (
         <div className="container">
           <div className="alert alert-danger mt-5" role="alert">
           No product found
          </div>
         </div>
        )}
      </InfiniteScroll>
      <HomeFilter showFilter={showFilter} setShowFilter={setShowFilter} />
    </>
  );
}

export default ProductCom;
