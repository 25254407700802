import React, { useState } from 'react';
import style from '../../styles/cart/single_cart.module.css';
import img from '../../image/pump-oil.png';
import { FaPlus, FaMinus } from 'react-icons/fa';
import { RiDeleteBin5Fill } from 'react-icons/ri';
import { HiOutlineCurrencyBangladeshi } from 'react-icons/hi';
import axios from 'axios';
import { API } from '../../data/BackEndData';
import Swal from 'sweetalert2';

const UpdateCart = ({ data, getCartTotal, getCartItem }) => {
  const [increaseLoading, setIncreaseLoading] = useState(false);
  const [decreaseLoading, setDecreaseLoading] = useState(false);

  // token
  const token = JSON.parse(localStorage.getItem('token'));

  // handle remove from cart
  const handleRemove = async (data) => {
    try {
      const res = await axios.delete(API + `/v1/removeCart/${data?._id}`, {
        headers: { Authorization: `bearer ${token}` },
      });
    } catch (error) {
    } finally {
      getCartTotal();
      getCartItem();
    }
  };

  // handle Increase cart quantity
  const handelInc = async (data) => {
    if (data?.quantity < data?.productId?.stock) {
      setIncreaseLoading(true);
      try {
        await axios.put(
          API + '/v1/updateCart',
          {
            quantity: data?.quantity + 1,
            cartId: data?._id,
          },
          {
            headers: { Authorization: `bearer ${token}` },
          }
        );
      } catch (e) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Something went wrong!',
        });
      } finally {
        getCartTotal();
        getCartItem();
      }
      setIncreaseLoading(false);
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Sorry! stock out',
      });
    }
  };

  // handle decrease cart quantity
  const handleDecrease = async (data) => {
    setDecreaseLoading(true);
    try {
      await axios.put(
        API + '/v1/updateCart',
        {
          quantity: data?.quantity - 1,
          cartId: data?._id,
        },
        {
          headers: { Authorization: `bearer ${token}` },
        }
      );
    } catch (e) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Something went wrong!',
      });
    } finally {
      getCartTotal();
      getCartItem();
    }
    setDecreaseLoading(false);
  };

  return (
    <article
      key={data._id}
      className={`${
        data?.quantity > data?.productId?.stock ? style.warning : style.wrapper
      } 
        ${data?.productId?.stock === 0 ? style.remove : style.wrapper}`}
    >
      <div className={style.left}>
        <img src={data?.productId?.productImage || img} alt="" />
      </div>
      <div className={style.middle}>
        <p className={style.c}>
          <b>{data?.productId?.companyName}</b>
        </p>
        <h5>{data?.productId?.title}</h5>

        <div className={style.m_r}>
          <p className={style.quate}>
            <HiOutlineCurrencyBangladeshi className={style.tk} />
            {(
              (data?.productId?.mainPrice -
                data?.productId?.mainPrice *
                  (data?.productId?.discount / 100)) *
              data?.quantity
            ).toFixed(2)}{' '}
            (
            {(
              data?.productId?.mainPrice -
              data?.productId?.mainPrice * (data?.productId?.discount / 100)
            ).toFixed(2)}{' '}
            * {data.quantity})
          </p>
          {/* <p className={style.quate}>{data?.productId?.stock}</p> */}
        </div>
      </div>

      {/* Conditionally text show */}
      {data?.quantity > data?.productId?.stock &&
        data?.productId?.stock !== 0 && (
          <h6 className={style.lowQuantityMessage}>
            Please update your quantity
          </h6>
        )}
      {data?.productId?.stock !== 0 ? (
        <div className={style.right}>
          <button
            className={style.hg}
            onClick={() => handelInc(data)}
            disabled={
              increaseLoading || data?.quantity >= data?.productId?.stock
            }
          >
            <FaPlus className={style.plus} />
          </button>
          <p className={style.quan}>{data.quantity}</p>
          <button
            className={style.hg}
            onClick={() => handleDecrease(data)}
            disabled={decreaseLoading}
          >
            <FaMinus className={style.minus} />
          </button>
        </div>
      ) : (
        <button
          style={{ backgroundColor: 'transparent', border: 'none' }}
          onClick={() => handleRemove(data)}
          // disabled={increaseLoading || data?.quantity >= data?.productId?.stock}
        >
          {/* <FaPlus className={style.plus} /> */}
          <RiDeleteBin5Fill size={35} />
        </button>
      )}
    </article>
  );
};

export default UpdateCart;
