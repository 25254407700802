import { FaSearch, FaBriefcaseMedical } from 'react-icons/fa';
import style from '../../styles/home/home_page_header.module.css';
import { useState, useEffect } from 'react';
import logo from '../../image/logo.jpeg';
import axios from 'axios';
import { API } from './../../data/BackEndData';

function HomePageHeader({
  showFilter,
  setShowFilter,
  setProducts,

  setS,
  setHasMore,
  page,
  setPage,
}) {
  const [sString, setSString] = useState('');

  const token = JSON.parse(localStorage.getItem('token'));

  const haldelSearc = async (e) => {
    const query = e.target.value.trimStart();

    // if (query) {
    //   const mProduct = copyProducts.filter((el) =>
    //     el.title.toLowerCase().includes(query.toLowerCase())
    //   );
    //   setProducts(mProduct);
    // } else {
    //   setProducts(copyProducts);
    // }

    let cancelToken;
    if (typeof cancelToken != typeof undefined) {
      cancelToken.cancel('Cancle previous request');
    }

    cancelToken = axios.CancelToken.source();

    // if(query){
    // steShowBanner(false);
    // setS(query)
    setSString(query);
    setPage(1);
    let limit = 100;

    try {
      const res = await axios.get(
        API + `/v1/get-product?page=1&s=${query}&limit=${limit}`,
        {
          headers: { Authorization: `bearer ${token}` },
          cancelToken: cancelToken.token,
        }
      );
      setProducts(res?.data);
      // setPage(1)
      if (res?.data.length === 0 || res?.data.length < limit) {
        setHasMore(false);
      } else {
        setHasMore(true);
      }
    } catch (e) {}

    // }
    // else{
    //   steShowBanner(true)
    // }
  };

  const handelSearch = () => {
    // setQuery
    setS(sString);
  };

  return (
    <section className={style.section}>
      <article className="container">
        <div className={style.section_wrapper}>
          <div>
            <img className={style.logo} src={logo} alt="" />
          </div>
          <div className={style.right}>
            <div>
              <div className="input-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search Here"
                  // onChange={(e) => haldelSearc(e)}
                  onChange={(e) => haldelSearc(e)}
                />
                <span
                  className="input-group-text"
                  id="basic-addon2"
                  onClick={handelSearch}
                >
                  <FaSearch />
                </span>
              </div>
            </div>
            <div>
              <FaBriefcaseMedical
                size={20}
                onClick={() => setShowFilter(!showFilter)}
              />
            </div>
          </div>
        </div>
      </article>
    </section>
  );
}

export default HomePageHeader;
