// import React, { useEffect } from 'react';
// import style from '../../styles/home/home_page_card.module.css';
// import defaultImage from '../../image/deafult3.png';
// import { HiOutlineCurrencyBangladeshi } from 'react-icons/hi';
// import { Link } from 'react-router-dom';
// import axios from 'axios';
// import { API } from '../../data/BackEndData';
// import Swal from 'sweetalert2';
// import { useState } from 'react';

// const ProductsCard = ({ product, handleClick, fetchCartItems, cartItems }) => {
//   const {
//     companyName,
//     title,
//     discount,
//     mainPrice,
//     _id,
//     hide,
//     stock,
//     productImage,
//   } = product;
//   console.log(product);
//   product.id = _id;
//   product.price = (mainPrice - mainPrice * (discount / 100)).toFixed(2);

//   const [active, setActivate] = useState(false);

//   // token
//   const token = JSON.parse(localStorage.getItem('token'));
//   // token
//   const user = JSON.parse(localStorage.getItem('user'));

//   // add to cart
//   const addToCart = async (id) => {
//     setActivate(true);
//     try {
//       const res = await axios.post(
//         API + '/v1/addToCart',
//         {
//           userId: user?._id,
//           productId: id,
//         },
//         {
//           headers: { Authorization: `bearer ${token}` },
//         }
//       );
//       fetchCartItems();
//       // navigate("/admin/orders")
//     } catch (e) {
//       console.log(e, 'something');
//       Swal.fire({
//         icon: 'error',
//         title: 'Oops...',
//         text: 'Something went wrong!',
//       });
//     }
//   };

//   return (
//     <div className={hide || stock < 1 ? 'd-none' : ''}>
//       <article className={style.card}>
//         <div className={style.wrapper}>
//           <div className={style.tab_img}>
//             {<img src={productImage} alt="" /> && (
//               <img src={defaultImage} alt="" />
//             )}
//           </div>
//           <div className={style.right}>
//             <div className={style.header}>
//               <p className={style.companyName_name}>{companyName}</p>
//               <p className={style.discount}>{discount}%</p>
//             </div>
//             <h4 className={style.title}>{title}</h4>
//             <div className={style.footer}>
//               <div className={style.f_left}>
//                 <p className={style.discount_price}>
//                   <span>
//                     <HiOutlineCurrencyBangladeshi className={style.tk} />
//                   </span>
//                   <span>
//                     {(mainPrice - mainPrice * (discount / 100)).toFixed(2)}
//                   </span>
//                 </p>
//                 <p className={style.main_price}>
//                   <span>
//                     <HiOutlineCurrencyBangladeshi className={style.tk} />
//                   </span>
//                   <span>{mainPrice}</span>
//                 </p>
//               </div>
//               <div>
//                 {cartItems?.find((el) => el?.productId?._id == _id) ? (
//                   <div className={style.e_w}>
//                     <Link to="/cart">Go To Cart</Link>
//                   </div>
//                 ) : (
//                   <button
//                     className={style.btn}
//                     onClick={() => addToCart(_id)}
//                     disabled={active}
//                   >
//                     Add To Cart
//                   </button>
//                 )}
//               </div>
//             </div>
//           </div>
//         </div>
//       </article>
//     </div>
//   );
// };

// export default ProductsCard;

// Image loading default if img url not found

import React, { useEffect, useState } from 'react';
import style from '../../styles/home/home_page_card.module.css';
import defaultImage from '../../image/deafult3.png';
import { HiOutlineCurrencyBangladeshi } from 'react-icons/hi';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { API } from '../../data/BackEndData';
import Swal from 'sweetalert2';

const ProductsCard = ({ product, handleClick, fetchCartItems, cartItems }) => {
  const {
    companyName,
    title,
    discount,
    mainPrice,
    _id,
    hide,
    stock,
    productImage,
  } = product;

  product.id = _id;
  product.price = (mainPrice - mainPrice * (discount / 100)).toFixed(2);

  const [active, setActivate] = useState(false);
  const [imageSrc, setImageSrc] = useState(productImage);

  // Token
  const token = JSON.parse(localStorage.getItem('token'));
  // User
  const user = JSON.parse(localStorage.getItem('user'));

  // Check if the image URL is valid
  useEffect(() => {
    const img = new Image();
    img.onload = () => setImageSrc(productImage);
    img.onerror = () => setImageSrc(defaultImage);
    img.src = productImage;
  }, [productImage]);

  // Add to cart
  const addToCart = async (id) => {
    setActivate(true);
    try {
      await axios.post(
        `${API}/v1/addToCart`,
        {
          userId: user?._id,
          productId: id,
        },
        {
          headers: { Authorization: `bearer ${token}` },
        }
      );
      fetchCartItems();
    } catch (e) {
      console.log(e, 'something');
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Something went wrong!',
      });
    }
  };

  return (
    <div className={hide || stock < 1 ? 'd-none' : ''}>
      <article className={style.card}>
        <div className={style.wrapper}>
          <div className={style.tab_img}>
            <img src={imageSrc} alt="Product" />
          </div>
          <div className={style.right}>
            <div className={style.header}>
              <p className={style.companyName_name}>{companyName}</p>
              <p className={style.discount}>{discount}%</p>
            </div>
            <h4 className={style.title}>{title}</h4>
            <div className={style.footer}>
              <div className={style.f_left}>
                <p className={style.discount_price}>
                  <span>
                    <HiOutlineCurrencyBangladeshi className={style.tk} />
                  </span>
                  <span>
                    {(mainPrice - mainPrice * (discount / 100)).toFixed(2)}
                  </span>
                </p>
                <p className={style.main_price}>
                  <span>
                    <HiOutlineCurrencyBangladeshi className={style.tk} />
                  </span>
                  <span>{mainPrice}</span>
                </p>
              </div>
              <div>
                {cartItems?.find((el) => el?.productId?._id === _id) ? (
                  <div className={style.e_w}>
                    <Link to="/cart">Go To Cart</Link>
                  </div>
                ) : (
                  <button
                    className={style.btn}
                    onClick={() => addToCart(_id)}
                    disabled={active}
                  >
                    Add To Cart
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </article>
    </div>
  );
};

export default ProductsCard;
