import React, { useEffect, useState } from 'react';
import style from '../../styles/home/home_page_card.module.css';
import ProductsCard from './ProductsCard';
import axios from 'axios';
import { API } from '../../data/BackEndData';

const HomePageCards = ({ products, loading }) => {
  const [cart, setCart] = useState([]);
  const [cartItems, setCartItems] = useState();

  const handleClick = (product) => {
    if (cart.indexOf(product) !== -1) return;
    setCart([...cart, product]);
  };

  // token
  const token = JSON.parse(localStorage.getItem('token'));
  // token
  const user = JSON.parse(localStorage.getItem('user'));

  // fetch all cart items
  const fetchCartItems = async () => {
    try {
      const res = await axios.get(API + `/v1/getAllCart/${user._id}`, {
        headers: { Authorization: `bearer ${token}` },
      });
      setCartItems(res.data);
    } catch (error) {}
  };

  useEffect(() => {
    fetchCartItems();
  }, []);

  return (
    <section className={style.section}>
      <div className="container">
        {loading ? (
          <div className="text-center mt-5">
            <div className="spinner-border text-primary" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        ) : (
          products.map((product, index) => (
            <ProductsCard
              product={product}
              key={index}
              handleClick={handleClick}
              fetchCartItems={fetchCartItems}
              cartItems={cartItems}
            />
          ))
        )}
      </div>
    </section>
  );
};

export default HomePageCards;
