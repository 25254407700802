import React, { useEffect, useState } from 'react';
import './Cart.css';
import SingleCart from './../../cart/SingleCart';
import axios from 'axios';
import { API } from '../../../data/BackEndData';
import { useNavigate } from 'react-router-dom';
import { HiOutlineCurrencyBangladeshi } from 'react-icons/hi';
import Swal from 'sweetalert2';

const Cart = () => {
  const token = JSON.parse(localStorage.getItem('token'));

  const [cartItems, setCartItems] = useState([]);
  const [cartTotal, setCartTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  let navigate = useNavigate();

  // get all cart items

  // user
  const user = JSON.parse(localStorage.getItem('user'));

  const fetchCartItems = async () => {
    try {
      const res = await axios.get(API + `/v1/getAllCart/${user._id}`, {
        headers: { Authorization: `bearer ${token}` },
      });
      setCartItems(res.data);
      // console.log('cartItem', res.data);
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Something went wrong!',
      });
    }
  };

  useEffect(() => {
    fetchCartItems();
  }, []);

  // get cart total
  const getCartTotal = () => {
    const Total = cartItems?.reduce((prev, current) => {
      const currentTotal =
        Number(
          current?.productId?.mainPrice -
            current?.productId?.mainPrice * (current?.productId?.discount / 100)
        ) * Number(current?.quantity);

      return prev + currentTotal;
    }, 0);
    setCartTotal(Total.toFixed(2));
  };

  useEffect(() => {
    getCartTotal();
  }, [cartItems]);

  // get total
  const getTotal = (item) => {
    const total = (
      (item?.productId?.mainPrice -
        item?.productId?.mainPrice * (item?.productId?.discount / 100)) *
      item?.quantity
    ).toFixed(2);
    return total;
  };

  // post item
  const postItems = () => {
    const cartMap = cartItems?.map((item) => {
      return {
        ...item?.productId,
        quantity: item?.quantity,
        price: Number(
          item?.productId?.mainPrice -
            item?.productId?.mainPrice * (item?.productId?.discount / 100)
        ).toFixed(2),
        id: item?.productId?._id,
        itemTotal: Number(getTotal(item)),
      };
    });
    return cartMap;
  };

  // handle order
  const handelOrder = async () => {
    setDisabled(true);
    await fetchCartItems();
    setLoading(true);
    try {
      const res = await axios.post(
        API + '/v1/order',
        { items: postItems(), cartTotal },
        {
          headers: { Authorization: `bearer ${token}` },
        }
      );
      setLoading(false);
      Swal.fire({
        icon: 'success',
        title: 'Order Placed Successfully. ',
        showConfirmButton: false,
        timer: 1500,
      });

      // emptyCart();
      return navigate('/my-order');
    } catch (e) {
      setLoading(false);
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Something went wrong!',
      });
    } finally {
      setTimeout(() => {
        setDisabled(false);
      }, 40000);
    }
  };

  // order button disable
  const disableButton = () => {
    let flag = false;
    if (cartItems?.length < 1) {
      flag = true;
    } else {
      cartItems?.forEach((item) => {
        if (item?.productId?.stock === 0) {
          flag = true;
          return;
        }
        if (item?.productId?.stock < item?.quantity) {
          flag = true;
          return;
        }
      });
    }

    return flag;
  };

  return (
    <article className="container">
      <div className="total mt-5">
        <span>Total Price of your Cart</span>
        <span>{cartTotal} Tk</span>
      </div>

      <SingleCart
        getCartTotal={getCartTotal}
        getCartItem={fetchCartItems}
        cartItems={cartItems}
      />
      <hr />

      <button
        className="Button"
        disabled={
          // cartItems?.length < 1 || loading ? true : false
          disableButton() || loading || disabled
        }
        onClick={() => handelOrder()}
      >
        {loading ? 'Loading...' : ` Order Now  ${cartTotal}`}
        <HiOutlineCurrencyBangladeshi className="text-white" />
      </button>
    </article>
  );
};

export default Cart;
