import axios from 'axios';
import { useEffect, useState } from 'react';
import { API } from '../data/BackEndData';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import Swal from 'sweetalert2';
import OrderCard from './OrderCard';
import { FaSearch } from 'react-icons/fa';

function AdminOrders() {
  const [loading, setLoading] = useState(false);
  const [orders, setOrders] = useState([]);
  const [filterOrder, setFilterOrder] = useState(orders);
  const [pharmacyName, setPharmacyName] = useState('');
  // const [page, setPage] = useState(1);
  // const [hasMore, setHasMore] = useState(true);
  // const [sString, setSString] = useState('');

  const token = JSON.parse(localStorage.getItem('token'));

  const fetchOrder = async () => {
    setLoading(true);
    try {
      const res = await axios.get(API + `/v1/orders`, {
        headers: { Authorization: `bearer ${token}` },
      });
      setOrders(res?.data);
      setLoading(false);
      // if (res?.data.length === 0 || res?.data.length < 20) {
      //   setHasMore(false);
      // } else {
      //   setPage(page + 1);
      // }
    } catch (e) {
      // console.log(e);
      toast.error(e.response.data.error || 'Reload this web page.');
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchOrder();
  }, []);

  // const getInfinitOrders = async () => {
  //   try {
  //     const res = await axios.get(
  //       API + `/v1/orders?page=${page}&com=${pharmacyName}`,
  //       {
  //         headers: { Authorization: `bearer ${token}` },
  //       }
  //     );
  //     setOrders([...orders, ...res?.data]);

  //     if (res?.data.length === 0 || res?.data.length < 20) {
  //       setHasMore(false);
  //     }
  //   } catch (e) {
  //     toast.error(e.response.data.error || 'Reload This Page!');
  //   }
  // };

  // infinit call
  // const fetchData = async () => {
  //   await getInfinitOrders();
  //   if (hasMore) {
  //     setPage(page + 1);
  //   }
  // };

  // Status Update handler
  // const handleStatus = async (id) => {
  //   const data = { _id: id, status: "complete" };
  //   console.log(data._id);
  //   try {
  //     const res = await axios.patch(
  //       API + `/v1/order/`,
  //       { _id: id, status: "complete" } ,
  //       {
  //         headers: { Authorization: `bearer ${token}` },
  //       }
  //     );

  //     // toast.success("Status Update successfully");
  //     // await fetchOrder();
  //   } catch (e) {
  //     toast.error(e.response.data.error || "Reload This Page!");
  //   }
  // };

  // Handle delete
  // const handelDelete = (id) => {
  //   // get modal
  //   Swal.fire({
  //     title: "Are you sure?",
  //     text: "You won't be able to revert this!",
  //     icon: "warning",
  //     showCancelButton: true,
  //     confirmButtonColor: "#3085d6",
  //     cancelButtonColor: "#d33",
  //     confirmButtonText: "Yes, delete it!",
  //   }).then(async (result) => {
  //     if (result.isConfirmed) {
  //       try {
  //         const res = await axios.delete(API + `/v1/order/${id}`, {
  //           headers: { Authorization: `bearer ${token}` },
  //         });
  //         await fetchOrder();
  //         const localData = JSON.parse(localStorage.getItem("completeOrder"));
  //         const deleteData = localData.filter((d) => d._id != id);
  //         localStorage.setItem("completeOrder", JSON.stringify(deleteData));
  //         Swal.fire("Deleted!", "Your file has been deleted.", "success");
  //       } catch (e) {
  //         Swal.fire({
  //           icon: "error",
  //           title: "Oops...",
  //           text: "Something went Wrong!",
  //         });
  //       }
  //     }
  //   });
  // };

  // handle search
  // handle search area
  const handleSearch = async (e) => {
    const query = e.target.value.toLowerCase().trimStart();
    setPharmacyName(query);
  };

  useEffect(() => {
    if (pharmacyName) {
      const filterOrder = orders.filter((order) =>
        order?.user?.pharmacyName.toLowerCase().includes(pharmacyName)
      );
      setFilterOrder(filterOrder);
    } else {
      setFilterOrder(orders);
    }
  }, [pharmacyName, orders]);

  return (
    <section className="mt-5">
      <article className="container">
        <h5 className="card-title">
          <b>All Order Here: {orders.length} </b>
        </h5>
        <div className="input-group">
          <input
            type="text"
            className="form-control"
            placeholder="Search Here"
            onChange={(e) => handleSearch(e)}
          />
          <span className="input-group-text" id="basic-addon2">
            <FaSearch />
          </span>
        </div>
        <br />
        {/* <InfiniteScroll
          dataLength={orders.length}
          next={fetchData}
          hasMore={hasMore}
        > */}
        <div>
          {filterOrder.length < 1 ? (
            <div
              className="alert alert-danger text-center text-secondary"
              role="alert"
            >
              <h5 className="mt-5 font-bold">
                Your Searched Pharmacy Name not found..! <br />
                Please Enter Correct Spelling!
              </h5>
            </div>
          ) : (
            <div>
              {filterOrder.map((data) => (
                <OrderCard
                  data={data}
                  // handelDelete={handelDelete}
                  key={data._id}
                />
              ))}
            </div>
          )}
        </div>
        {/* </InfiniteScroll> */}
      </article>
    </section>
  );
}

export default AdminOrders;
