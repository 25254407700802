import { useEffect, useState } from 'react';

import axios from 'axios';
import { API } from './../data/BackEndData';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { FaSearch } from 'react-icons/fa';
import { MdStorage } from 'react-icons/md';
import InfiniteScroll from 'react-infinite-scroll-component';
import ProductFilter from './ProductFilter';

function ProductPage({ setS }) {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const token = JSON.parse(localStorage.getItem('token'));
  const [page, setPage] = useState(2);
  const [sString, setSString] = useState('');
  const [hasMore, setHasMore] = useState(true);
  const [showFilter, setShowFilter] = useState(false);

  let navigate = useNavigate();

  // get ALl added products
  const getProduct = async () => {
    setLoading(true);
    try {
      const res = await axios.get(API + '/v1/get-product', {
        headers: { Authorization: `bearer ${token}` },
      });
      setProducts(res?.data);
      setLoading(false);
    } catch (e) {
      toast.error(e.response.data.error || 'Reload This Page!');
      setLoading(false);
    }
  };

  useEffect(() => {
    getProduct();
  }, []);

  const getInfinitProduct = async () => {
    setLoading(false);

    try {
      const res = await axios.get(API + `/v1/get-product?page=${page}`, {
        headers: { Authorization: `bearer ${token}` },
      });
      setProducts([...products, ...res?.data]);
      if (res?.data.length === 0 || res?.data.length < 20) {
        setHasMore(false);
      }
    } catch (e) {
      toast.error(e.response.data.error || 'Reload This Page!');
    }
  };

  // infinity call
  const fetchData = async () => {
    await getInfinitProduct();
    if (hasMore) {
      setPage(page + 1);
    }
  };

  // handle search area
  const handleSearch = async (e) => {
    const query = e.target.value.trimStart();

    // if (query) {
    //   const mProduct = copyProducts.filter((el) =>
    //     el.title.toLowerCase().includes(query.toLowerCase())
    //   );
    //   setProducts(mProduct);
    // } else {
    //   setProducts(copyProducts);
    // }

    let cancelToken;
    if (typeof cancelToken != typeof undefined) {
      cancelToken.cancel('Cancle previous request');
    }

    cancelToken = axios.CancelToken.source();
    setSString(query);
    setPage(1);
    let limit = 100;

    try {
      const res = await axios.get(
        API + `/v1/get-product?page=1&s=${query}&limit=${limit}`,
        {
          headers: { Authorization: `bearer ${token}` },
          cancelToken: cancelToken.token,
        }
      );
      setProducts(res?.data);
      // setPage(1)
      if (res?.data.length === 0 || res?.data.length < limit) {
        setHasMore(false);
      } else {
        setHasMore(true);
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <section className="mt-5">
      <article className="container">
        <div className="d-flex justify-content-between">
          <div>
            <h5 className="position-relative">
              <b>Product List</b>
              {!loading && (
                <span className="badge bg-primary ms-2">{products.length}</span>
              )}
            </h5>
          </div>
          <div>
            <button
              className="btn btn-sm btn-outline-primary"
              onClick={() => navigate('/add-product')}
            >
              Add Product
            </button>
          </div>
        </div>
        <hr />

        <div className="input-group" style={{ width: '75%' }}>
          <input
            style={{ width: '75%' }}
            type="text"
            className="form-control"
            placeholder="Search Here"
            onChange={(e) => handleSearch(e)}
          />
          <span className="input-group-text" id="basic-addon2">
            <FaSearch />
          </span>
          <div>
            <MdStorage
              size={35}
              style={{
                marginLeft: '20px',
                fontSize: '25px',
                cursor: 'pointer',
              }}
              onClick={() => setShowFilter(!showFilter)}
            />
          </div>
        </div>

        {loading ? (
          <div className="text-center mt-4">
            <div className="spinner-border text-primary" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        ) : (
          <div>
            {products.length < 1 ? (
              <div
                className="alert alert-danger text-center text-secondary"
                role="alert"
              >
                <h5 className="mt-5 font-bold">
                  Your Searched Product not found..! <br />
                  Please Enter Correct Spelling!
                </h5>
              </div>
            ) : (
              <InfiniteScroll
                dataLength={products.length}
                next={fetchData}
                hasMore={hasMore}
              >
                {' '}
                <div>
                  <div>
                    <table className="table table table-striped table-hover">
                      <thead>
                        <tr>
                          <th>Products Name</th>
                          <th>Quantity</th>
                        </tr>
                      </thead>
                      <tbody>
                        {products.map(
                          (data, index) =>
                            data.stock > 0 && (
                              <tr key={index}>
                                <td className={'my-row'}>{data.title}</td>
                                <td className={'my-row'}>{data.stock}</td>
                                <td className={'my-row'}>
                                  <Link
                                    to={`/admin/product/${data?._id}`}
                                    className="btn btn-sm btn-danger"
                                  >
                                    Update
                                  </Link>
                                </td>
                              </tr>
                            )
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </InfiniteScroll>
            )}
          </div>
        )}
        <ProductFilter showFilter={showFilter} setShowFilter={setShowFilter} />
      </article>
    </section>
  );
}

export default ProductPage;
